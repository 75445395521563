<script>
import commitment from '@/components/statusRuler/commitment'
export default {
  extends: commitment,
  data () {
    return {
      status: 'cancellation'
    }
  }
}
</script>
